<template>
  <div>
    <el-container>
      <el-container>
        <!-- 中间内容 -->
        <el-main>
          <h2 class="depTitle">参会人员端模块管理</h2>
          <div class="RightDiv">
            <div class="firDiv">
              <el-select v-model="moduleStatus" clearable placeholder="请选择发布状态" @change="statusChange(moduleStatus)">
                <el-option v-for="item in getStatusOptions" :key="item.id" :label="item.statusName" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="firDiv">
              <el-button type="primary" @click="addModule">新增模板</el-button>
            </div>
          </div>
          <el-table :data="tableData" border center>
            <el-table-column type="index" label="序号" width="55" align="center">
            </el-table-column>
            <el-table-column prop="title" label="模块名称" align="center">
            </el-table-column>
            <el-table-column prop="icon" label="图标" align="center">
            </el-table-column>
            <el-table-column prop="sort" label="排序" align="center">
            </el-table-column>
            <el-table-column prop="release" label="发布状态" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.release=='0'">未发布</div>
                <div v-else-if="scope.row.release=='1'">已发布</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="250">
              <template style="display: flex" slot-scope="scope">
                <el-button size="mini" type="primary" @click="editModule(scope.row)">修改</el-button>
                <el-button size="mini" type="danger" @click="deleteModule(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
          </el-pagination>

          <el-dialog :title="winName" :visible.sync="revsuggdialogVisible" top="5px" width="1000px" :before-close="revsuggdialogVisibleClose">
            <el-form :model="AddDateModel" ref="AddDateModel" :rules="AddModuleRules" label-width="100px">
              <el-form-item label="模块名称：" prop="title" class="lognClass">
                <el-input v-model="AddDateModel.title" placeholder="请填写模块名称"></el-input>
              </el-form-item>
              <el-form-item label="图标编码：" prop="icon" class="lognClass">
                <el-input v-model="AddDateModel.icon" placeholder="请填写图标编码"></el-input>
              </el-form-item>
              <el-form-item label="排序：" prop="sort" class="lognClass">
                <el-input v-model="AddDateModel.sort" type="number" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请填写模板顺序"></el-input>
              </el-form-item>
              <el-form-item label="内容：" prop="content">
                <div style="border: 1px solid #ccc;" v-if="revsuggdialogVisible">
                  <Toolbar
                      style="border-bottom: 1px solid #ccc"
                      :editor="editor"
                      :defaultConfig="toolbarConfig"
                      :mode="mode"
                  />
                  <Editor v-if="revsuggdialogVisible"
                      style="height: 500px; overflow-y: hidden;"
                      v-model="html"
                      :defaultConfig="editorConfig"
                      :mode="mode"
                      @onCreated="onCreated"
                  />
                </div>
              </el-form-item>
              <el-form-item class="depar-dia btnCen formBotton widBomtn">
                <el-button v-if="addModuleClick" type="primary" :loading="addModuleClickKing" @click="saveModule">保存</el-button>
                <el-button v-else type="info" :loading="addModuleClickKing"></el-button>

                <el-button v-if="openModuleClick" type="primary" :loading="openModuleClickKing" @click="openModule">发布</el-button>
                <el-button v-else type="info" :loading="openModuleClickKing"></el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<script>
import _qs from "qs";
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default Vue.extend({
  components: { Editor, Toolbar },
  data() {
    return {
      AddModuleRules:{
        title: [
          { required: true, message: "请填写模板名称", trigger: "blur" },
        ],
        icon: [
          { required: true, message: "请填写图标编码", trigger: "blur" },
        ],
        sort: [
          { required: true, message: "请填写模板顺序", trigger: "blur" },
        ]
      },
      // open:false,
      winName:'',
      moduleStatus:'',
      addModuleClick: true,
      addModuleClickKing: false,

      openModuleClick: true,
      openModuleClickKing: false,
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      revsuggdialogVisible:false,
      tableData:[],
      AddDateModel:{},
      editor: null,
      html: '',
      moduleId:'',
      getStatusOptions:[{"id":0,"statusName":"未发布"},{"id":1,"statusName":"已发布"}],
      toolbarConfig: { },
      editorConfig: {
        placeholder: '请输入内容...',
        // autoFocus: false,
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 图片上传
          uploadImage: {
            server: Vue.prototype.GLOBAL+'/api/menuManage/uploadPosters',
            fieldName: 'modulePhoto',
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 10 * 1024 * 1024, // 10M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              // token: ,
              // otherKey: 'yyy'
              // file:''
            },
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,

            // 自定义增加 http  header
            headers: {
              Authorization: window.sessionStorage.getItem("token"),
              // otherKey: 'xxx'
            },

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,

            // 超时时间，默认为 10 秒
            timeout: 10 * 1000, //10 秒

            // 上传前
            onBeforeUpload(files) {
              // Message({
              //   message: '图片正在上传中,请耐心等待',
              //   duration: 0,
              //   customClass: 'uploadTip',
              //   iconClass: 'el-icon-loading',
              //   showClose: true
              // });
              return files;
            },
            // 自定义插入图片
            customInsert(res, insertFn) {
              // 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
              // 先关闭等待的Message
              // Message.closeAll();
              // if (res.code === 200) {
              //   Message.success({
              //     message: `${res.data.originalName} 上传成功`
              //   });
              // } else {
              //   Message.error({
              //     message: `${res.data.originalName} 上传失败，请重新尝试`
              //   });
              // }
              insertFn(Vue.prototype.GLOBAL+res.data);
            },

            // 单个文件上传成功之后
            onSuccess(file, res) {
              console.log(`${file.name} 上传成功`, res);
            },

            // 单个文件上传失败
            onFailed(file, res) {
              console.log(`${file.name} 上传失败`, res);
            },

            // 上传进度的回调函数
            onProgress(progress) {
              console.log('progress', progress);
              // progress 是 0-100 的数字
            },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              console.log(`${file.name} 上传出错`, err, res);
            }
          },
          // 视频上传
          uploadVideo: {
            fieldName: 'file',
            server: '/api/conferManage/uploadPosters',

            // 单个文件的最大体积限制，默认为 10M
            maxFileSize: 50 * 1024 * 1024, // 50M

            // 最多可上传几个文件，默认为 5
            maxNumberOfFiles: 3,
            // 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['video/*'],

            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              // token: 'xxx',
              // otherKey: 'yyy'
            },

            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,

            // 自定义增加 http  header
            headers: {
              // Accept: 'text/x-json',
              // otherKey: 'xxx'
            },

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,

            // 超时时间，默认为 30 秒
            timeout: 1000 * 1000, // 1000 秒,
            // 上传之前触发
            onBeforeUpload(file) {
              // Message({
              //   message: '视频正在上传中,请耐心等待',
              //   duration: 0,
              //   customClass: 'uploadTip',
              //   iconClass: 'el-icon-loading',
              //   showClose: true
              // });
              return file;
            },
            // 自定义插入视频
            customInsert(res, insertFn) {
              // 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
              // 先关闭等待的Message
              // Message.closeAll();
              // if (res.code === 200) {
              //   Message.success({
              //     message: `${res.data.originalName} 上传成功`
              //   });
              // } else {
              //   Message.error({
              //     message: `${res.data.originalName} 上传失败，请重新尝试`
              //   });
              // }
              insertFn(res.data.link, res.data.link);
            },
            // 上传进度的回调函数
            onProgress(progress) {
              console.log(progress);
              // onProgress(progress) {       // JS 语法
              // progress 是 0-100 的数字
            },

            // // 单个文件上传成功之后
            // onSuccess(file, res) {
            //   console.log(`${file.name} 上传成功`, res);
            //   this.successMsg(file);
            // },

            // // 单个文件上传失败
            // onFailed(file, res) {
            //   console.log(`${file.name} 上传失败`, res);
            //   this.errorMsg(file);
            // },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              console.log(`${file.name} 上传出错`, err, res);
              Notification.error({
                title: '错误',
                message: `${file.name} 上传失败，请重新尝试`
              });
            }
          }
        }
      },
      mode: 'default', // or 'simple'

    }
  },
  methods: {
    async modulePageList(){
      let data = _qs.stringify({
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
        release: this.moduleStatus,
        conferenceId: window.sessionStorage.getItem('conferenceId')
      });
      let { data: res } = await this.$axios.modulePageList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    addModule(){
      // this.open = true;
      this.winName = '新增模块';
      this.revsuggdialogVisible=true;
    },
    editModule(row){
      this.revsuggdialogVisible=true;
      this.moduleId = row.id;
      // this.open = true;
      //创建编辑器
      // console.log(row.body)
      this.winName = '编辑模块信息';
      //
      //
      this.AddDateModel = JSON.parse(JSON.stringify(row));
      // var htmlBody = row.body;
      // if(row.body && row.body.startsWith('<table')){
      //   htmlBody = '<p></p>'+row.body;
      // }
      this.html = row.body;
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.modulePageList();
    },
    revsuggdialogVisibleClose(){
      this.revsuggdialogVisible=false;
      this.AddDateModel={};
      this.editor.setHtml();
      this.html = '';
      // console.log(this.editor.getHtml());
    },
    // 发布
    openModule(){
      this.openModuleClick = false;
      this.openModuleClickKing = true;
      if(this.winName == '编辑模块信息'){
        this.$refs.AddDateModel.validate(async (valid) => {
          if(valid){
            let data = _qs.stringify({
              title:this.AddDateModel.title,
              icon:this.AddDateModel.icon,
              sort:this.AddDateModel.sort,
              body:this.html,
              release:1,
              conferenceId:window.sessionStorage.getItem('conferenceId'),
              id:this.moduleId
            });
            let { data: res } = await this.$axios.editModule(data);
            // console.log(res)
            if (res.code == 401) {
              this.$router.push("/login");
            } else if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.revsuggdialogVisibleClose();
              this.pageNum = 1;
              this.modulePageList();
            }else{
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
            this.openModuleClick = true;
            this.openModuleClickKing = false;
          }
        })
      }
      // 保存
      else{
        this.$refs.AddDateModel.validate(async (valid) => {
          if(valid){
            let data = _qs.stringify({
              title:this.AddDateModel.title,
              icon:this.AddDateModel.icon,
              sort:this.AddDateModel.sort,
              body:this.html,
              release:1,
              conferenceId:window.sessionStorage.getItem('conferenceId')
            });
            let { data: res } = await this.$axios.saveModule(data);
            // console.log(res)
            if (res.code == 401) {
              this.$router.push("/login");
            } else if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.revsuggdialogVisibleClose();
              this.pageNum = 1;
              this.modulePageList();
            }else{
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
            this.openModuleClick = true;
            this.openModuleClickKing = false;
          }
        })
      }
    },
    // 保存
    saveModule() {
      this.addModuleClick = false;
      this.addModuleClickKing = true;

      if(this.winName == '编辑模块信息'){
        this.$refs.AddDateModel.validate(async (valid) => {
          if(valid){
            let data = _qs.stringify({
              title:this.AddDateModel.title,
              icon:this.AddDateModel.icon,
              sort:this.AddDateModel.sort,
              body:this.html,
              release:0,
              conferenceId:window.sessionStorage.getItem('conferenceId'),
              id:this.moduleId
            });
            let { data: res } = await this.$axios.editModule(data);
            // console.log(res)
            if (res.code == 401) {
              this.$router.push("/login");
            } else if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.revsuggdialogVisibleClose();
              this.pageNum = 1;
              this.modulePageList();
            }else{
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
            this.addModuleClick = true;
            this.addModuleClickKing = false;
          }
        })
      }
      // 新增
      else{
        this.$refs.AddDateModel.validate(async (valid) => {
          if(valid){
            let data = _qs.stringify({
              title:this.AddDateModel.title,
              icon:this.AddDateModel.icon,
              sort:this.AddDateModel.sort,
              body:this.html,
              release:0,
              conferenceId:window.sessionStorage.getItem('conferenceId')
            });
            let { data: res } = await this.$axios.saveModule(data);
            // console.log(res)
            if (res.code == 401) {
              this.$router.push("/login");
            } else if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.revsuggdialogVisibleClose();
              this.pageNum = 1;
              this.modulePageList();
            }else{
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
            this.addModuleClick = true;
            this.addModuleClickKing = false;
          }
        })
      }
    },
    async deleteModule(row){
      const confirmResult = await this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消删除");
      }
      let data = _qs.stringify({
        id:row.id
      });
      let { data: res } = await this.$axios.deleteModule(data);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.pageNum = 1;
        this.modulePageList();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    statusChange(val){
      this.pageNum = 1;
      // console.log(val)
      this.moduleStatus=val
      this.modulePageList();
    }
  },
  created() {
    this.modulePageList();
  },
  mounted() {
    // // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    // }, 1500)
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
})
</script>

<style lang="less" scoped>
.el-pagination{
  text-align: center;
}
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}

.btnDiv{
  text-align: center;
  margin-top: 20px;
  button {
    width: 150px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}
.depart-addnum {
  width: 100%;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
.el-main {
  .table-title {
    display: flex;
    margin-bottom: 10px;
    .index-add {
      margin-left: 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.text-ellipsis{
  width:380px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.RightDiv{
  float: right;
  margin-bottom: 20px;
  display: flex;
  .firDiv{
    margin-left: 10px;
  }
}

.revSty{
  text-align: center;
  .el-button{
    width:150px;
    margin-top: 20px;
  }
}
/deep/.lognClass {
  width: 100%;
}
.formBotton {
  width: 100%;
  text-align: center;
  margin-left: -50px;
}
.widBomtn button{
  width:200px
}
///deep/ .conClass .el-form-item__content {
//  width: 76%;
//}
</style>
